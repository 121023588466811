import {useMemo} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import type {ISlotBannerData} from '@hy-vee/react-web-and-mobile-ui-components';

import {
    CreateEcommerceClickEventMutation,
    CreateEcommerceClickEventMutationVariables,
    CreateEcommerceImpressionEventMutation,
    CreateEcommerceImpressionEventMutationVariables
} from 'codegen/graphql';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {
    createEcommerceClickEventMutation,
    createEcommerceImpressionEventMutation
} from 'client/graphql/mutations/sponsored-product-tracking-mutations';
import IApolloQuery from 'client/types/apollo-query';
import type {getBanners, getBannersVariables} from 'autogen/getBanners';
import {getBannersQuery} from 'client/graphql/queries/banner-queries';
import {getAuthType} from 'client/views/components/search/helpers/search-result-helpers';
import {useCitrusSessionId} from 'client/hooks/use-citrus-session-id';

import {useCustomerData} from './customer-hooks';

export interface IUseCitrusActions {
    reportClick: (adId: string) => void;
    reportImpression: (adId: string) => void;
}

export const useCitrusActions = (): IUseCitrusActions => {
    const {activeCartId} = useCustomerData();

    const [createImpressionEvent] = useMutation<
        CreateEcommerceImpressionEventMutation,
        IApolloQuery<CreateEcommerceImpressionEventMutationVariables>
    >(createEcommerceImpressionEventMutation);

    const [createClickEvent] = useMutation<
        CreateEcommerceClickEventMutation,
        IApolloQuery<CreateEcommerceClickEventMutationVariables>
    >(createEcommerceClickEventMutation);

    return useMemo(() => {
        const reportClick = (adId: string) => {
            createClickEvent({
                context: {
                    fetchOptions: {
                        keepalive: true
                    }
                },
                variables: {
                    authType: activeCartId ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
                    cartId: activeCartId ? activeCartId : null,
                    vendorAdId: adId
                }
            });
        };

        const reportImpression = (adId: string) => {
            createImpressionEvent({
                variables: {
                    authType: activeCartId ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
                    cartId: activeCartId ? activeCartId : null,
                    vendorAdId: adId
                }
            });
        };

        return {
            reportClick,
            reportImpression
        };
    }, [createClickEvent, createImpressionEvent, activeCartId]);
};

export interface IUseBanners {
    bannerImages?: ISlotBannerData[] | null;
    loading: boolean;
}

// Use banners is querying marketing assets API for citrus banners
export const useBanners = (slotIds: string[] = [], placement: 'HOME' | 'SEARCH' = 'HOME'): IUseBanners => {
    const customerData = useCustomerData(true);
    const authType = getAuthType(customerData);
    const sessionId = useCitrusSessionId();

    const {data: bannerImages, loading} = useQuery<getBanners, IApolloQuery<getBannersVariables>>(getBannersQuery, {
        errorPolicy: 'all',
        fetchPolicy: 'cache-and-network',
        variables: {
            authType,
            cartId: customerData?.activeCartId,
            customerUuid: customerData?.customerUuid,
            placement, // Required
            sessionId,
            slotIds, // Required
            storeId: customerData?.storeId
        }
    });

    // Fixing type differences between graphql query and what the component needs
    const BannerFormatted: ISlotBannerData[] | null | undefined = bannerImages?.bannerImages?.map((bannerImage) => {
        return {
            ...bannerImage,
            link: bannerImage.link ?? '',
            productIds: (bannerImage.productIds?.filter((id) => id !== null) as number[]) ?? ([] as number[])
        };
    });

    return {
        bannerImages: BannerFormatted,
        loading
    };
};
