import {gql} from '@apollo/client';

import {productImageFragment} from './product-images-queries';
import {wicItemFragment} from './product-queries';

const deliveryAddressFragment = gql`
    fragment IDeliveryAddress on deliveryAddress {
        deliveryAddressId
        fulfillmentLocations {
            fulfillmentLocationId
        }
        firstName
        lastName
        addressOne
        addressTwo
        city
        state
        zip
    }
`;

const pickupLocationFragment = gql`
    fragment IPickupLocation on pickupLocation {
        pickupLocationId
        pickupStoreId
        name
        address
        city
        state
        zip
        isActive
        pickupLocationHasLocker
    }
`;

const storeFragment = gql`
    fragment IStore on store {
        storeId
        name
        address
        city
        state
        zip
    }
`;

const cartItemFragment = gql`
    ${productImageFragment}
    ${wicItemFragment}

    fragment IGroceryOptionsCartItem on cartItem {
        cartItemId
        cartId
        productId
        quantity
        quantityType
        tax
        retailItem {
            item {
                itemId
                unitAverageWeight
                WicItems(locationIds: $locationIds) @include(if: $wicEnabled) {
                    ...IWicItemFragment
                }
            }
            soldByUnitOfMeasure {
                code
            }
            tagPrice
            tagPriceQuantity
            ecommerceTagPrice
            ecommerceTagPriceQuantity
        }
        storeProduct {
            storeProductId
            onSale
            onFuelSaver
            isWeighted
            isActive
            isAlcohol
            fuelSaver
            price
            priceMultiple
            returnAmount
            taxRate
            department {
                departmentId
            }
            departmentGroup {
                departmentGroupId
                name
            }
            product {
                brandName
                name
                productId
                size
                averageWeight
                isNotEligibleForDelivery
                productImages(where: {isPrimary: true, size: "THUMBNAIL"}) {
                    ...ProductImageFragment
                }
            }
        }
    }
`;

const aislesOnlinePromotionFragment = gql`
    fragment IAislesOnlinePromotion on aislesOnlinePromotion {
        aislesOnlinePromotionId
        promoCode
        description
        threshold
        amount
        isPercentage
        isActive
        startDate
        endDate
        allowedUsages
        isStackable
        isFuelSaver
        newCustomersOnly
        isAvailableForAllOrderTypes
        isAvailableForAllStores
    }
`;

const fulfillmentLocationFragment = gql`
    fragment IFulfillmentLocation on fulfillmentLocation {
        canFulfillAlcohol
        fulfillmentFees {
            feeWithMembership
            feeWithoutMembership
            orderMinimumAmount
            duration
            priorityType
        }
        fulfillmentTimes {
            duration
            fulfillmentScheduleId
            windowStart
            windowEnd
            priorityType
        }
        fulfillmentLocationId
        fulfillmentStoreId
        locationName
        fulfillmentStoreLocation {
            locationId
            ecommerceStatus
        }
    }
`;

const madeToOrderCartItemFragment = gql`
    fragment IMadeToOrderCartItem on madeToOrderCartItem {
        cartItemId
        cartId
        productId
        quantity
        storeId
        fulfillmentFee
        fulfillmentType
        fulfillmentWindowStart
        fulfillmentWindowEnd
        tax
        parentCartItemId
        price
        taxExemptAmount
        madeToOrderCartItemModifiers {
            cartItemId
        }
        madeToOrderProduct {
            productId
            categoryId
            departmentId
            price
            name
        }
    }
`;

const clpeCartPromotionsFragment = gql`
    fragment IClpeCartPromotions on cart {
        clpeCartPromotions {
            cartItemIds
            description
            promotionNumber
            rewardType
            rewardAmount
            rewardPercentage
        }
    }
`;

export const cartPromotionsFragment = gql`
    ${aislesOnlinePromotionFragment}

    fragment ICartPromotions on cart {
        cartPromotions {
            cartPromotionId
            isApplied
            aislesOnlinePromotion {
                ...IAislesOnlinePromotion
            }
        }
    }
`;

export const getGroceryOptions = gql`
    ${deliveryAddressFragment}
    ${cartItemFragment}
    ${pickupLocationFragment}
    ${storeFragment}
    ${fulfillmentLocationFragment}
    ${cartPromotionsFragment}
    ${clpeCartPromotionsFragment}

    query getGroceryOptions($wicEnabled: Boolean = false, $locationIds: [ID!]) {
        myCustomer {
            customerId
            customerMemberships: customerMembershipsV2 {
                customerMembershipId
                isActive
                membership {
                    membershipId
                    membershipType
                }
            }
            firstName
            fuelSaverCard {
                fuelSaverCardUuid
            }
            activeCart {
                cartId
                cartItems {
                    ...IGroceryOptionsCartItem
                }
                customerId
                ...ICartPromotions
                ...IClpeCartPromotions
                fulfillmentExpiration
                fulfillmentId
                fulfillmentWindowStart
                fulfillmentWindowEnd
                fulfillmentType
                fulfillmentLocation {
                    ...IFulfillmentLocation
                    requireSeparateAlcoholOrder
                }
                deliveryAddress {
                    ...IDeliveryAddress
                }
                pickupLocation {
                    ...IPickupLocation
                }
                store {
                    ...IStore
                }
                instructions
                bagPreferenceType
                smsNotificationPhoneNumber
                requestsPickupLocationToBeWheelchairAccessible
            }
        }
    }
`;

export const getCartInfoForGroceryOptions = gql`
    query getCartInfoForGroceryOptions {
        myCustomer {
            customerId
            activeCart {
                cartId
                canBuyAgeRestrictedItems
                leaveUnattended
                bagPreferenceType
                instructions
                requestsPickupLocationToBeWheelchairAccessible
                smsNotificationPhoneNumber
            }
        }
    }
`;

export const getGroceryOptionsWithCartFulfillmentInfo = gql`
    ${pickupLocationFragment}

    query getGroceryOptionsWithCartFulfillmentInfo {
        myCustomer {
            customerId
            activeCart {
                cartId
                bagPreferenceType
                smsNotificationPhoneNumber
                instructions
                requestsPickupLocationToBeWheelchairAccessible
                fulfillmentType
                fulfillmentId
                fulfillmentExpiration
                pickupLocation {
                    ...IPickupLocation
                }
            }
        }
    }
`;

export const getMadeToOrderOptions = gql`
    ${madeToOrderCartItemFragment}

    query GetMadeToOrderOptions($cartId: String!) {
        madeToOrderCart(cartId: $cartId) {
            cartId
            legacyCartId
            madeToOrderCartItems {
                ...IMadeToOrderCartItem
            }
        }
    }
`;

export const getMyCustomerActiveCartId = gql`
    query getMyCustomerActiveCartId {
        myCustomer {
            customerId
            activeCart {
                cartId
            }
        }
    }
`;
