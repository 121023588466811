import {gql} from '@apollo/client';

export const couponFragmentV4 = gql`
    fragment ICouponV4 on CouponV4 {
        couponId
        category
        imageUrl
        brand
        clipStartDate
        clipEndDate
        expirationDate
        clipped
        value
        valueText
        description
        terms
        upcs
        offerState
    }
`;

export const getCouponsV4ForCartItemUpcs = gql`
    ${couponFragmentV4}

    query GetCouponsV4ForCartItemUpcs($fuelSaverCardUuid: String, $filters: CouponsFilter) {
        couponsV4(fuelSaverCardUuid: $fuelSaverCardUuid, filters: $filters) {
            ...ICouponV4
        }
    }
`;

export const getCouponV4ByCouponIdQuery = gql`
    ${couponFragmentV4}

    query GetCouponV4ByCouponIdQuery($couponId: ID!) {
        couponV4(couponId: $couponId) {
            ...ICouponV4
        }
    }
`;

export const getRetailItemsByUPCs = gql`
    query getRetailItemsByUPCs($ean13s: [String!]!, $storeId: Int!, $storeIdString: String!) {
        locationsByBusinessCode(businessCode: $storeIdString) {
            locationId
            retailItemsByEAN13s(ean13s: $ean13s) {
                retailItemId
                basePrice
                ecommerceTagPrice
                ecommerceTagPriceQuantity
                memberTagPrice
                memberTagPriceQuantity
                ecommerceStatus
                soldByUnitOfMeasure {
                    code
                }
                item {
                    itemId
                    description
                    unitAverageWeight
                    product {
                        productId
                        averageWeight
                        name
                        size
                        productImages(where: {isPrimary: true, size: "THUMBNAIL"}) {
                            productId
                            isPrimary
                            uri
                            type
                            size
                            viewType
                        }
                        storeProduct(storeId: $storeId) {
                            storeProductId
                            isWeighted
                            price
                            priceMultiple
                            storeProductDescriptions {
                                type
                                description
                            }
                        }
                    }
                }
            }
        }
    }
`;
